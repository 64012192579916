/*
 * Copyright 2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *   http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

body {
  background-color: #FAFAFA;
}

.pageRoot {
    border: 1px solid #FAFAFA;
}

.title-text {
    margin: auto;
    width: 50%;
}

.wheelNavButtonGroup {
    font-family: Arial;
    position: absolute;
    top: 2%;
    left: 1%;
}

#app .well {
    padding: 8px 12px;
    background-color: #eaeded;
    border-radius: 4px;
    background-image: none;
}

#app .page-header {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    margin: 0 0 18px;
    border-bottom: 1px solid #879196;
}

thead th {
    text-transform:uppercase;
    text-align:left;
    color: #545b64;
    font-size: 14px;
}

button:focus {outline:0;}

.title {
    background-color: #FAFAFA;
    text-align: center;
    font-family: Helvetica;
    color: #232F3E;
}

.notification {
    float: right;
}

.errorNotification {
    color: #df3312;
}

.tableHeader {
    font-size: 20px;
    color: #232F3E;
}
